import React from 'react';

interface HeartProps {
    className: string;
    size?: number; // Size in pixels
}

const Heart: React.FC<HeartProps> = ({ className, size = 32 }) => {
    return (
        <div className={`absolute ${className}`}>
            <img src="/heart.png" alt="Heart" style={{ width: size, height: size }} />
        </div>
    );
};

export default Heart;
