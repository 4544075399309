import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useForm, SubmitHandler } from 'react-hook-form';
import CountryCodeSelector from './CountryCodeSelector';
import { GigiSVG } from '../assets/GigiSVG';
import { OcticonSVG } from '../assets/Octicon';
import SuccessMessage from './SuccessMessage';

type FormValues = {
    name: string;
    age: number;
    phone: string;
    city: string;
    student: boolean;
    university?: string; // Optional field for university name
};

const RegistrationForm: React.FC = () => {
    const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm<FormValues>({
        defaultValues: {
            //@ts-ignore
            student: 'false'
        }
    });
    const [message, setMessage] = useState('');
    const [position, setPosition] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [userName, setUserName] = useState('');
    const [countryCode, setCountryCode] = useState('+1');
    const [isModalVisible, setModalVisible] = useState(false);
    const [inviteLink] = useState('https://get.gigiapp.com');
    const studentStatus = watch('student');
 useEffect(() => {
    // Parse URL parameters
    const params = new URLSearchParams(window.location.search);
console.log(params)
    const userId = params.get('userid');

    if (userId) {
      // Set the cookie
      localStorage["user_id"] = userId;

      // Remove user_id from URL
      params.delete('userid');
      const newUrl = `${window.location.pathname}?${params.toString()}`;
window.history.replaceState({},'',newUrl)
    }
  }, []);


    const onSubmit: SubmitHandler<FormValues> = async data => {
        setIsLoading(true);
        setMessage('');
        setPosition(null);
data["user_id"] = localStorage["user_id"]
        try {
            const fullPhoneNumber = `${countryCode} ${data.phone}`;
            //@ts-ignore
            const response = await axios.post(`${import.meta.env.VITE_API_URL}`, {
                ...data,
                phone: fullPhoneNumber,
            });
            setMessage(response.data.message);
            setPosition(response.data.position);
            setUserName(data.name);
            setIsSubmitted(true);
        } catch (error) {
            // console.error('Error submitting form', error);
            // setMessage('An error occurred while submitting the form: ' + error.message);
            setMessage('An error occurred while submitting the form');
        } finally {
            setIsLoading(false);
        }
    };

    if (isSubmitted) {
        return (
            <SuccessMessage 
            userName={userName} 
            position={position} 
            inviteLink={inviteLink} 
        />
        );
    }

    return (
        <div className="min-h-screen flex justify-center bg-gradient-to-t to-transparent via-[#F91D5F] from-[#F91D5F]">
            <div>
                <div className="flex justify-center">
                <div className='relative top-[40px]'>
                    <GigiSVG />
                    </div>
                </div>
                <div className='px-4 pb-4 w-full max-w-md '>
                    <h1 className="text-6xl font-thunder mt-3 mb-3 text-center text-white">JOIN THE WAITLIST</h1>
                    <p className="mb-3 text-xl text-left text-white">Apply now and we'll text you once it's your turn to join Gigi !</p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-4 ">
                            <input
                                id="name"
                                placeholder='Your name*'
                                {...register('name', { required: 'Name is required' })}
                                className="w-full px-3 py-3 border rounded-lg shadow-sm focus:outline-none focus:ring focus:border-pink-300"
                            />
                        </div>
                        <div className="mb-4">
                            <input
                                id="age"
                                type="number"
                                placeholder='Your age*'
                                {...register('age', { required: 'Age is required', valueAsNumber: true })}
                                className="w-full px-3 py-3 border rounded-lg shadow-sm focus:outline-none focus:ring focus:border-pink-300"
                            />
                        </div>
                        <div className="mb-4">
                            <div className="flex">
                                <button
                                    type="button"
                                    onClick={() => setModalVisible(true)}
                                    className="inline-flex items-center px-3 rounded-l-lg border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm"
                                >
                                    {countryCode}
                                </button>
                                <input
                                    id="phone"
                                    placeholder='Your phone number*'
                                    type="tel"
                                    {...register('phone', { required: 'Phone number is required' })}
                                    className="w-full px-3 py-3 border rounded-r-lg shadow-sm focus:outline-none focus:ring focus:border-pink-300"
                                />
                            </div>
                        </div>
                        <div className="mb-4">
                            <input
                                id="city"
                                placeholder='Your city*'
                                {...register('city', { required: 'City is required' })}
                                className="w-full px-3 py-3 border rounded-lg shadow-sm focus:outline-none focus:ring focus:border-pink-300"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-white text-xl text-sm font-semibold mb-1">Are you a student?</label>
                            <div className="flex items-center justify-between bg-white text-center rounded-lg overflow-hidden shadow-md">
                                <input
                                    type="radio"
                                    id="student-yes"
                                    value="true"
                                    {...register('student', { required: 'Please select your student status' })}
                                    className="hidden"
                                />
                                <label 
                                    htmlFor="student-yes" 
                                    //@ts-ignore
                                    className={`w-1/2 py-3 cursor-pointer ${studentStatus === 'true' ? 'text-[#F91D5F]' : 'text-[#B1B1B0]'}`}
                                >
                                    Yes
                                </label>
                                <input
                                    type="radio"
                                    id="student-no"
                                    value="false"
                                    {...register('student', { required: 'Please select your student status' })}
                                    className="hidden"
                                />
                                <label 
                                    htmlFor="student-no" 
                                    //@ts-ignore
                                    className={`w-1/2 py-3 cursor-pointer ${studentStatus === 'false' ? 'text-[#F91D5F]' : 'text-[#B1B1B0]'}`}
                                >
                                    No
                                </label>
                            </div>
                        </div>
                        {/*@ts-ignore  */}
                        {studentStatus === 'true' && (
                            <div className="mb-4">
                                <label className="block text-white text-xl text-sm mb-1" htmlFor="university">Your university*</label>
                                <input
                                    id="university"
                                    {...register('university', { required: 'University is required' })}
                                    className="w-full px-3 py-3 border rounded-lg shadow-sm focus:outline-none focus:ring focus:border-pink-300"
                                />
                            </div>
                        )}

                        {(errors.name || errors.age || errors.city || errors.phone) && (
                            <div className='flex my-3 items-center text-white'>
                                <OcticonSVG />
                                <p className='ml-2 font-semibold'>Fill in all the required infos to continue</p>
                            </div>
                        )}

                        <button type="submit" className="w-full bg-gradient-to-b font-semibold from-white via-white to-[#8E8780] shadow-inner-custom shadow-custom text-gray-800 py-4 px-4 rounded-[18px] hover:bg-gray-300 focus:outline-none focus:ring focus:border-pink-300">
                            {isLoading ? 'Submitting...' : 'Join the waitlist'}
                        </button>
                    </form>
                    {message && (
                        <div className="mt-6 text-center">
                            <p className="text-pink-700 font-bold">{message}</p>
                            {position !== null && <p className="text-pink-700">Your position in the waiting list: {position}</p>}
                        </div>
                    )}
                </div>
            </div>
            <div className='min-h-screen flex items-center'>
                <CountryCodeSelector
                    isVisible={isModalVisible}
                    onClose={() => setModalVisible(false)}
                    onSelect={(code) => setCountryCode(code)}
                />
            </div>
        </div>
    );
};

export default RegistrationForm;
