import React, { useRef } from 'react';

const CopyInput = ({ value }) => {
    const inputRef = useRef(null);

    const handleClick = () => {
        if (inputRef.current) {
            //@ts-ignore
            inputRef.current.select();
            document.execCommand('copy');
        }
    };

    return (
        <input
            ref={inputRef}
            type="text"
            value={value}
            readOnly
            onClick={handleClick}
            className="w-full px-3 py-2 border rounded-lg shadow-sm mb-4 focus:outline-none focus:ring focus:border-pink-300"
        />
    );
};

export default CopyInput;
