import React from 'react';
import { GigiSVG } from '../assets/GigiSVG';
import Heart from './Heart';
import CopyInput from './CopyInput';

interface SuccessMessageProps {
    userName: string;
    position: number | null;
    inviteLink: string;
}

const SuccessMessage: React.FC<SuccessMessageProps> = ({ userName, position, inviteLink }) => {
    const urlWithUTM = `${inviteLink}?utm_source=${userName}&utm_medium=referral&utm_campaign=invite`;
    const shareMessage = `Join me on Gigi! Here is my invite link: ${urlWithUTM}`;

    const handleCopy = () => {
        const textArea = document.createElement("textarea");
        textArea.value = urlWithUTM;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
    };

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Invite friends to Gigi',
                    text: shareMessage,
                    url: urlWithUTM,
                });
                // console.log('Successful share');
            } catch (error) {
                // console.log('Error sharing', error);
            }
        } else {
            handleCopy(); // Fallback to copy link for desktop users
        }
    };

    const isWebShareSupported = !!navigator.share;

    return (
        <div className="flex flex-col relative min-h-screen bg-gradient-to-br from-[#F91D5F] to-[#841ec7]">
            <div className='relative flex items-center justify-center'>
                <div className='relative top-[35px]'>
                <GigiSVG />
                </div>
                <Heart className="top-[140px] left-[20px]" size={45} />
                <Heart className="top-[50px] right-[30px]" size={45} />
            </div>
            <div className='flex justify-center items-center'>
                <div className="relative px-8 pb-8 rounded-lg w-full max-w-md text-center">
                <h1 className="text-2xl font-bold mb-2 text-white">We count you in</h1>
                <div className='relative'>                
                    <Heart className="top-[-70px] left-[70px]" size={30} />                
                    <h2 className="text-7xl uppercase font-thunder mb-2 text-white">{userName}</h2>
                </div>
                <p className="text-2xl text-white">You're</p>
                <p className="text-7xl font-bold text-white font-thunder">#{position}</p>
                <p className="text-2xl mb-6 text-white">on the list !</p>
                <div className="relative bg-white/80 p-8 rounded-xl shadow-md w-full max-w-md text-center">
                    <p className="mb-3 text-xl">Invite some friends to move up the list</p>
                    {!isWebShareSupported &&
                       <CopyInput value={urlWithUTM} />
                    }
                    <button
                        onClick={handleShare}
                        className="w-full bg-[#F91D5F] text-white py-3 px-6 rounded-lg shadow-lg hover:opacity-75 transition duration-300 ease-in-out mb-6">
                        {isWebShareSupported ? 'Invite friends' : 'Copy Invite Link'}
                    </button>
                </div>

                {/* Hearts */}
                <Heart className="top-[20px] right-[10px]" size={55} />
                <Heart className="bottom-[290px] left-[350px]" size={54} />
                <Heart className="top-[50%] left-[10px] transform -translate-y-1/2" size={32} />
            </div>
            </div>
        </div>
    );
};

export default SuccessMessage;
