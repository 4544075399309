export const countryCodes = [
    { code: '+1', country: '🇺🇸 United States' },
    { code: '+44', country: '🇬🇧 United Kingdom' },
    { code: '+33', country: '🇫🇷 France' },
    { code: '+49', country: '🇩🇪 Germany' },
    { code: '+61', country: '🇦🇺 Australia' },
    { code: '+91', country: '🇮🇳 India' },
    { code: '+81', country: '🇯🇵 Japan' },
    { code: '+86', country: '🇨🇳 China' },
    { code: '+7', country: '🇷🇺 Russia' },
    { code: '+39', country: '🇮🇹 Italy' },
    { code: '+34', country: '🇪🇸 Spain' },
    { code: '+55', country: '🇧🇷 Brazil' },
    { code: '+52', country: '🇲🇽 Mexico' },
    { code: '+27', country: '🇿🇦 South Africa' },
    { code: '+31', country: '🇳🇱 Netherlands' },
    { code: '+32', country: '🇧🇪 Belgium' },
    { code: '+46', country: '🇸🇪 Sweden' },
    { code: '+47', country: '🇳🇴 Norway' },
    { code: '+41', country: '🇨🇭 Switzerland' },
    { code: '+30', country: '🇬🇷 Greece' },
    { code: '+48', country: '🇵🇱 Poland' },
    { code: '+45', country: '🇩🇰 Denmark' },
    { code: '+353', country: '🇮🇪 Ireland' },
    { code: '+351', country: '🇵🇹 Portugal' },
    { code: '+43', country: '🇦🇹 Austria' },
    { code: '+90', country: '🇹🇷 Turkey' },
    { code: '+82', country: '🇰🇷 South Korea' },
    { code: '+63', country: '🇵🇭 Philippines' },
    { code: '+60', country: '🇲🇾 Malaysia' },
    { code: '+62', country: '🇮🇩 Indonesia' },
    { code: '+64', country: '🇳🇿 New Zealand' },
    { code: '+20', country: '🇪🇬 Egypt' },
    { code: '+972', country: '🇮🇱 Israel' },
    { code: '+65', country: '🇸🇬 Singapore' },
    { code: '+66', country: '🇹🇭 Thailand' },
    { code: '+84', country: '🇻🇳 Vietnam' },
    { code: '+855', country: '🇰🇭 Cambodia' },
    { code: '+880', country: '🇧🇩 Bangladesh' },
    { code: '+92', country: '🇵🇰 Pakistan' },
    { code: '+98', country: '🇮🇷 Iran' },
    { code: '+971', country: '🇦🇪 United Arab Emirates' },
    { code: '+966', country: '🇸🇦 Saudi Arabia' },
    { code: '+374', country: '🇦🇲 Armenia' },
    { code: '+994', country: '🇦🇿 Azerbaijan' },
    { code: '+973', country: '🇧🇭 Bahrain' },
    { code: '+975', country: '🇧🇹 Bhutan' },
    { code: '+964', country: '🇮🇶 Iraq' },
    { code: '+962', country: '🇯🇴 Jordan' },
    { code: '+94', country: '🇱🇰 Sri Lanka' },
    { code: '+263', country: '🇿🇼 Zimbabwe' }
];
