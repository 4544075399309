import React, { useState } from 'react';
import { countryCodes } from '../utils/countryCodes';

interface CountryCodeSelectorProps {
    isVisible: boolean;
    onClose: () => void;
    onSelect: (code: string) => void;
}

const CountryCodeSelector: React.FC<CountryCodeSelectorProps> = ({ isVisible, onClose, onSelect }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredCodes = countryCodes.filter(code =>
        code.country.toLowerCase().includes(searchTerm.toLowerCase()) ||
        code.code.includes(searchTerm)
    );

    if (!isVisible) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-4 rounded-lg shadow-lg w-full max-w-md">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">Select your country code</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">✖</button>
                </div>
                <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg mb-4"
                />
                <ul className="max-h-64 overflow-y-auto">
                    {filteredCodes.map((code, index) => (
                        <li key={index} className="flex justify-between items-center py-2 px-3 cursor-pointer hover:bg-gray-100" onClick={() => {
                            onSelect(code.code);
                            onClose();
                        }}>
                            <span>{code.country}</span>
                            <span>{code.code}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default CountryCodeSelector;
