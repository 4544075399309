import React from "react"

export const OcticonSVG = () => {
    return (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_19_8103)">
            <path d="M0 9.5C0 7.11305 0.948212 4.82387 2.63604 3.13604C4.32387 1.44821 6.61305 0.5 9 0.5C11.3869 0.5 13.6761 1.44821 15.364 3.13604C17.0518 4.82387 18 7.11305 18 9.5C18 11.8869 17.0518 14.1761 15.364 15.864C13.6761 17.5518 11.3869 18.5 9 18.5C6.61305 18.5 4.32387 17.5518 2.63604 15.864C0.948212 14.1761 0 11.8869 0 9.5ZM9 2.1875C7.0606 2.1875 5.20064 2.95792 3.82928 4.32928C2.45792 5.70064 1.6875 7.5606 1.6875 9.5C1.6875 11.4394 2.45792 13.2994 3.82928 14.6707C5.20064 16.0421 7.0606 16.8125 9 16.8125C10.9394 16.8125 12.7994 16.0421 14.1707 14.6707C15.5421 13.2994 16.3125 11.4394 16.3125 9.5C16.3125 7.5606 15.5421 5.70064 14.1707 4.32928C12.7994 2.95792 10.9394 2.1875 9 2.1875ZM7.3125 9.21875C7.3125 8.99497 7.40139 8.78036 7.55963 8.62213C7.71786 8.46389 7.93247 8.375 8.15625 8.375H9.28125C9.50503 8.375 9.71964 8.46389 9.87787 8.62213C10.0361 8.78036 10.125 8.99497 10.125 9.21875V12.3125H10.4062C10.63 12.3125 10.8446 12.4014 11.0029 12.5596C11.1611 12.7179 11.25 12.9325 11.25 13.1562C11.25 13.38 11.1611 13.5946 11.0029 13.7529C10.8446 13.9111 10.63 14 10.4062 14H8.15625C7.93247 14 7.71786 13.9111 7.55963 13.7529C7.40139 13.5946 7.3125 13.38 7.3125 13.1562C7.3125 12.9325 7.40139 12.7179 7.55963 12.5596C7.71786 12.4014 7.93247 12.3125 8.15625 12.3125H8.4375V10.0625H8.15625C7.93247 10.0625 7.71786 9.97361 7.55963 9.81537C7.40139 9.65714 7.3125 9.44253 7.3125 9.21875ZM9 7.25C8.70163 7.25 8.41548 7.13147 8.2045 6.9205C7.99353 6.70952 7.875 6.42337 7.875 6.125C7.875 5.82663 7.99353 5.54048 8.2045 5.3295C8.41548 5.11853 8.70163 5 9 5C9.29837 5 9.58452 5.11853 9.7955 5.3295C10.0065 5.54048 10.125 5.82663 10.125 6.125C10.125 6.42337 10.0065 6.70952 9.7955 6.9205C9.58452 7.13147 9.29837 7.25 9 7.25Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_19_8103">
            <rect width="18" height="18" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
            </defs>
        </svg>
    )
}

