import RegistrationForm from './components/RegistrationForm'

function App() {
  return (
    <>
      <RegistrationForm/>
    </>
  )
}

export default App
